<template>
    <v-app class="wrap">
        <SubAppBar />
        <v-container fluid class="fill-height" >
            <MainView class="fill-height"/>
        </v-container>
        <OtcFooter></OtcFooter>
    </v-app>
</template>

<script>
    export default {
        name: 'Detail',
        components: {
            SubAppBar: () => import('./core/SubAppBar'),
            MainView: () => import('./core/MainView'),
            OtcFooter: () => import("@/components/OtcFooter"),
        },
        created () {
        
        },
        data: () => ({

        }),
    }
</script>

<style lang="scss">
    .partner_position{
        position: absolute;
    }
</style>